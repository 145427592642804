import React from "react";
import HeaderIconsLg from "./HeaderIconsLg";
import HeaderIconsSm from "./HeaderIconsSm";

const HeaderIcons = () => {
  return (
    <div className="flex">
      <HeaderIconsLg />
      <HeaderIconsSm />
    </div>
  );
};

export default HeaderIcons;
