import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";

const Scroller = () => {
  useEffect(() => {
    const savedScrollPosition = localStorage.getItem("scrollPosition");
    setTimeout(() => {
      window.scrollTo(0, parseInt(savedScrollPosition));
    }, 5000);
  }, []);

  return <></>;
};

export default Scroller;
