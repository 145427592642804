import React from "react";
import Loading from "./Loading";

export default function Page(props) {
  return (
    <div>
      <Loading isLoading={props.isLoading}>{props.children}</Loading>
    </div>
  );
}
