import React, { createContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import TopBar from "./Components/TopBar";
import Home from "./Pages/Home";
import NewbosProduct from "./Pages/Product/NewbosProduct";
import MeetAndShare from "./Pages/MeetAndShare";

function App() {
  return (
    <div className="App">
      <TopBar></TopBar>
      <div className="lg:mt-20 mt-[65px]">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="/product/:productname"
            element={<NewbosProduct />}
          ></Route>
          <Route path="/meetandshare" element={<MeetAndShare />}></Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
