import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const FontContext = createContext();

const useFont = () => useContext(FontContext);

const FontProvider = ({ children }) => {
  // const [font, setFont] = useState({
  //   data: {
  //     id: 1,
  //     attributes: {
  //       HTX: "font-raleway font-light text-[15px]",
  //       HDTX: "font-raleway font-bold text-sm",
  //       DTX: "font-raleway font-normal text-xs",
  //       SUHTX: "font-raleway font-bold text-2xl",
  //       SUHDX: "font-raleway font-normal text-base",
  //       SHDTX: "font-raleway font-bold text-xs",
  //       SHDDTX: "font-raleway font-normal text-xs",
  //       createdAt: "2024-01-16T11:57:06.968Z",
  //       updatedAt: "2024-02-02T08:22:08.308Z",
  //       publishedAt: "2024-01-16T11:57:25.183Z",
  //       HDX: "font-raleway font-normal text-[15px]",
  //     },
  //   },
  // });
  const [font, setFont] = useState();
  const [loading, setLoading] = useState(true);
  const useFont = () => useContext(FontContext);

  useEffect(() => {
    loadFont();
  }, []);

  const loadFont = async () => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/font")
      .then(({ data }) => {
        setFont(data.data.attributes);
        setLoading(false);
      });
  };

  return (
    <FontContext.Provider value={{ font, loading }}>
      {children}
    </FontContext.Provider>
  );
};

export { FontProvider, useFont };
