import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";
import HomeButton from "../Components/HomeButton";

const MeetAndShare = () => {
  const fontValue = useFont();
  const font = fontValue?.font;

  const [error, setError] = useState(null);
  const [meetShareData, setmeetShareData] = useState([]);
  const [query, setQuery] = useState("");
  const [personData, setPersonData] = useState([]);
  // const [detailVisible, setDetailVisible] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [isPopupVisible, setPopupVisibility] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/mtsh?populate=*")
      .then(({ data }) => {
        setmeetShareData(data.data);
        setPersonData(data.data.attributes?.MTSH_PERSON);
      })
      .catch((error) => setError(error));
  }, []);

  const handleInputChange = (event) => {
    console.log(personData);
    if (personData) {
      const newQuery = event.target.value;
      setQuery(newQuery);

      const filtered = meetShareData.attributes?.MTSH_PERSON?.filter((item) =>
        item.MTSH_PER_NAME.toLowerCase().includes(newQuery.toLowerCase())
      );
      setPersonData(filtered);
    }
  };

  // function toggleSelectedPerson(personName) {
  //   setDetailVisible(!detailVisible);
  //   setSelectedPerson(personName);
  // }

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="bg-gray-300 ">
        <div className="lg:max-w-[1100px] m-auto ">
          <div className="flex justify-center pt-10 pb-5">
            <p className={`${font?.SUHTX} text-black text-center`}>
              {meetShareData.attributes?.MTSH_SUHTX_01}
            </p>
          </div>
          <div className="flex justify-center  pb-5">
            <div className="relative w-64">
              <input
                type="text"
                id="person-search"
                className="block p-2.5 w-full h-12 z-20 text-sm text-gray-900 bg-gray-50 border-s-gray-50 border-s-2 border border-gray-300 shadow-lg  focus:border-transparent focus:ring-0 "
                value={query}
                onChange={handleInputChange}
              />

              <img
                src="/SearchIcon.svg"
                alt=""
                className="w-10 h-10 absolute top-1 end-1"
              />
              <span className="sr-only">Search</span>
              {/* </button> */}
            </div>
          </div>
          <div className="flex justify-center pt-3 pb-5">
            <p className={`${font?.SUHDX} text-black text-center`}>
              {meetShareData.attributes?.MTSH_SUHDX_01?.split(/\n/).map(
                (line) => (
                  <span key={`SUHTX_01$${line}`}>
                    {line} <br />
                  </span>
                )
              )}
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pt-4 pb-40 pr-2 pl-2">
            {personData?.map((person) => (
              <div key={person.MTSH_PER_NAME}>
                <div className="border shadow-lg   bg-[#FDFDFD] h-auto min-w-fit">
                  <div className="flex items-center ">
                    <div className={`${font?.HDTX} text-black p-4 w-full`}>
                      {person.MTSH_PER_NAME}
                    </div>
                    <div
                      className={
                        person.id === selectedPerson.id ? "hidden" : "mr-2"
                      }
                      onClick={() => setSelectedPerson(person)}
                    >
                      <img
                        src="/DownArrowIcon.svg"
                        alt=""
                        className="w-5 h-5  "
                      />
                    </div>
                    <div
                      className={
                        person.id !== selectedPerson.id ? "hidden" : "mr-2 "
                      }
                      onClick={() => setSelectedPerson(0)}
                    >
                      <img
                        src="/UpArrowIcon.svg"
                        alt="upArrow"
                        className="w-5 h-5 "
                      />
                    </div>
                  </div>
                  <div
                    id="expandableDiv"
                    className={
                      person.id !== selectedPerson.id
                        ? "hidden"
                        : "border shadow-lg bg-[#FDFDFD] h-auto min-w-fit"
                    }
                  >
                    <a href={person.MTSH_PER_DDCDE_LNK}>
                      <div className="p-4 leading-4 ">
                        <span className={`${font?.HDTX} text-black `}>
                          {person.MTSH_PER_DDTXT}
                        </span>

                        <span className={`${font?.HDTX} text-[#DD8732] pl-2`}>
                          {person.MTSH_PER_DDCDE}
                        </span>
                        <br />
                        <span className={`${font?.DTX} text-[#7F7F7F] `}>
                          {person.MTSH_PER_DDDTX}
                        </span>
                      </div>
                    </a>
                    <a href={person.MTSH_PER_JMCDE_LNK}>
                      <div className="p-4 leading-4">
                        <span className={`${font?.HDTX} text-black `}>
                          {person.MTSH_PER_JMTXT}
                        </span>
                        <span className={`${font?.HDTX} text-[#DD8732] pl-2`}>
                          {person.MTSH_PER_JMCDE}
                        </span>
                        <br />
                        <span className={`${font?.DTX} text-[#7F7F7F] `}>
                          {person.MTSH_PER_JMDTX}
                        </span>
                      </div>
                    </a>
                    <div
                      className="p-4 leading-4 cursor-pointer"
                      onClick={() => setPopupVisibility(true)}
                    >
                      <div className={`${font?.HDTX} text-black `}>
                        {person.MTSH_PER_DMTXT}
                      </div>
                      <div className="flex">
                        <div className={`${font?.HDTX} text-black `}>
                          {person.MTSH_PER_DMTXT2}
                        </div>
                        <div className={`${font?.HDTX} text-[#DD8732] pl-2 `}>
                          {person.MTSH_PER_DMCDE}
                        </div>
                      </div>

                      <span className={`${font?.DTX} text-[#7F7F7F] `}>
                        {person.MTSH_PER_DMDTX}
                      </span>
                    </div>
                    {/* <div className="flex items-center">
                      <div className=" text-black p-4 w-full"></div>
                      <div
                        className="mr-2 "
                        onClick={() => setSelectedPerson(0)}
                      >
                        <img
                          src="/UpArrowIcon.svg"
                          alt="upArrow"
                          className="w-5 h-5 "
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="h-2 bg-[#DD8732]"></div>
                </div>
              </div>
            ))}
          </div>
          {isPopupVisible ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="relative p-6 flex-auto">
                      <div className="flex justify-center pt-3 pb-3">
                        <p className={`${font?.HTX} text-black text-center`}>
                          {meetShareData.attributes?.TBPUPHTX_1?.split(
                            /\n/
                          ).map((line) => (
                            <span key={`TBPUPHTX_1$${line}`}>
                              {line} <br />
                            </span>
                          ))}
                        </p>
                      </div>
                      <p className={`${font?.HTX} text-black text-center`}>
                        {selectedPerson.MTSH_PER_DMCDE}
                      </p>
                    </div>

                    {/*footer*/}
                    <p className={`${font?.HTX} text-black text-center`}>
                      Yes Or No..
                    </p>
                    <div className="flex items-center justify-center pb-4 pt-1">
                      <div className="cursor-pointer">
                        <a href={selectedPerson.MTSH_PER_DMCDE_LNK}>
                          <span className="slider mr-3 flex h-10 w-20 items-center rounded-full p-1  bg-gradient-to-r from-[#FFD500] via-[#FFAA00] to-[#F48B00]">
                            <span className="dot h-8 w-8 rounded-full bg-white  translate-x-10"></span>
                          </span>
                        </a>
                      </div>

                      <div
                        onClick={() => setPopupVisibility(false)}
                        className="cursor-pointer"
                      >
                        <span className="slider mr-3 flex h-10 w-20 items-center rounded-full p-1  bg-[#C9CDD1]">
                          <span className="dot h-8 w-8 rounded-full bg-white  "></span>
                        </span>
                      </div>
                    </div>
                    <div className="h-2 bg-[#DD8732]"></div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      </div>
      <HomeButton />
    </Page>
  );
};

export default MeetAndShare;
