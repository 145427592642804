import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";

const HeaderIconsLg = () => {
  const fontValue = useFont();
  const font = fontValue?.font;
  const [error, setError] = useState(null);
  const [tbData, setTbData] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/tb?populate=*")
      .then(({ data }) => {
        setTbData(data.data);
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="hidden lg:block ">
        <div className="flex cursor-pointer">
          <a href={tbData.attributes?.TBLNK_1}>
            <div className="flex hover:scale-105 duration-300 p-2">
              <div className="pt-2 pr-2">
                <img
                  src={
                    process.env.REACT_APP_STRAPI_API_URL +
                    tbData.attributes?.TBIMG_01?.data?.attributes?.url
                  }
                  alt="phone"
                  className="w-10  lg:w-14 object-cover"
                />
              </div>
              <div>
                <p className="leading-3">
                  <span
                    className={`font-raleway font-bold text-base text-white`}
                  >
                    {tbData.attributes?.TBHTX_1}
                  </span>
                  <br />
                  {tbData.attributes?.TBHDX_01?.split(/\n/).map((line) => (
                    <span
                      key={`TBHDX_01${line}`}
                      className={`font-raleway font-bold text-sm text-white `}
                    >
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </a>
          <a href={tbData.attributes?.TBLNK_2} target="_blank">
            <div className="flex hover:scale-105 duration-300 p-2">
              <div className="pt-2 pr-2">
                <img
                  src={
                    process.env.REACT_APP_STRAPI_API_URL +
                    tbData.attributes?.TBIMG_02?.data?.attributes?.url
                  }
                  alt="training"
                  className=" w-10  lg:w-14 object-cover"
                />
              </div>
              <div>
                <p className="leading-3">
                  <span
                    className={`font-raleway font-bold text-base text-white`}
                  >
                    {tbData.attributes?.TBHTX_2}
                  </span>
                  <br />
                  {tbData.attributes?.TBHDX_02?.split(/\n/).map((line) => (
                    <span
                      key={`TBHDX_02${line}`}
                      className={`font-raleway font-bold text-sm text-white`}
                    >
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </a>
          <a href={tbData.attributes?.TBLNK_3}>
            <div className="flex hover:scale-105 duration-300 p-2">
              <div className="pt-2 pr-2">
                <img
                  src={
                    process.env.REACT_APP_STRAPI_API_URL +
                    tbData.attributes?.TBIMG_03?.data?.attributes?.url
                  }
                  alt="phone"
                  className="w-10  lg:w-14 object-cover"
                />
              </div>
              <div>
                <p className="leading-3">
                  <span
                    className={`font-raleway font-bold text-base text-white`}
                  >
                    {tbData.attributes?.TBHTX_3}
                  </span>
                  <br />
                  {tbData.attributes?.TBHDX_03?.split(/\n/).map((line) => (
                    <span
                      key={`TBHDX_03${line}`}
                      className={`font-raleway font-bold text-sm text-white `}
                    >
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </Page>
  );
};

export default HeaderIconsLg;
