import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import HeaderIcons from "./HeaderIcons";

const TopBar = () => {
  let navigate = useNavigate();

  return (
    <div className="fixed top-0 z-10 w-full bg-[#161C30] max-h-40 min-h-[65px]">
      <div className="lg:max-w-[1100px] m-auto ">
        <div className="flex justify-between items-center  sticky">
          <div className="flex items-center px-2 pt-2 pb-2 ">
            <img
              src="/newbos_partners_header_logo.png"
              alt="mainlogo"
              className=" w-60 lg:w-80 cursor-pointer"
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            ></img>
          </div>
          <HeaderIcons />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
