import React, { useContext } from "react";
import { useFont, isLoading } from "../contexts/FontContext.js";
import axios from "axios";
import { useEffect, useState } from "react";

// import { FontContext } from "../App.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Page from "../Components/Page.jsx";
import { BlocksRenderer, BlocksContent } from "@strapi/blocks-react-renderer";

const Main = () => {
  const fontValue = useFont();

  const font = fontValue?.font;
  const [error, setError] = useState(null);
  const [coverdata, setCoverdata] = useState([]);
  const [isReadMoreVisible, setReadMoreVisibility] = useState(false);

  let loading = fontValue?.loading;

  useEffect(() => {
    loading = true;
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/mnpg?populate=*")
      .then(({ data }) => {
        setCoverdata(data.data);
        loading = false;
      })
      .catch((error) => setError(error));
  }, []);

  const mnpgHdx1 = coverdata.attributes?.MNPG_HDX_01?.split(/\n/);

  return (
    <>
      <Page isLoading={loading}>
        <div className="max-w[3840px] mx-auto relative">
          <div>
            <Carousel
              showStatus={false}
              autoPlay={true}
              showIndicators={false}
              showThumbs={false}
              infiniteLoop={true}
              showArrows={false}
              transitionTime={2000}
              centerMode={false}
              interval={10000}
              stopOnHover={false}
            >
              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  coverdata.attributes?.MNPG_IMG_01A?.data?.attributes?.url
                }
                alt="main1"
                className="w-full lg:max-h-[550px] max-h-[180px] lg:min-h-[550px] object-cover"
              ></img>

              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  coverdata.attributes?.MNPG_IMG_01B?.data?.attributes?.url
                }
                alt="main2"
                className="w-full lg:max-h-[550px] max-h-[180px] lg:min-h-[550px] object-cover"
              ></img>

              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  coverdata.attributes?.MNPG_IMG_01C?.data?.attributes?.url
                }
                alt="main3"
                className="w-full lg:max-h-[550px] max-h-[180px] lg:min-h-[550px] object-cover"
              ></img>

              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  coverdata.attributes?.MNPG_IMG_01D?.data?.attributes?.url
                }
                alt="main4"
                className="w-full lg:max-h-[550px] max-h-[180px] lg:min-h-[550px] object-cover"
              ></img>

              <img
                src={
                  process.env.REACT_APP_STRAPI_API_URL +
                  coverdata.attributes?.MNPG_IMG_01E?.data?.attributes?.url
                }
                alt="main5"
                className="w-full lg:max-h-[550px] max-h-[180px] lg:min-h-[550px] object-cover"
              ></img>
            </Carousel>
            <div className=" lg:absolute w-full h-auto  lg:top-52 top-36 ">
              <div className="lg:max-w-[1100px] m-auto  lg:py-6  ">
                {/* Industry experts and..  */}
                <div className="absolute lg:-top-14 top-28 w-[260px] h-[90px] bg-[#6b684d]">
                  <p className="flex flex-col items-start gap-[3px] relative self-stretch tracking-[0] leading-[normal] w-[250px] h-[63px]  top-[14px] left-[15px] ">
                    {coverdata.attributes?.MNPG_HTX_01?.split(/\n/).map(
                      (line, i) => (
                        <span
                          key={"MNPG_HTX_01" + i}
                          className={`${font?.HTX} text-white tracking-[0]`}
                        >
                          {line}
                          <br />
                        </span>
                      )
                    )}
                  </p>
                </div>

                {/* From day one... */}
                <div className="lg:flex lg:flex-row ">
                  <div className="flex lg:w-[574px] h-auto bg-[#eaeaea] justify-start lg:pb-0 pb-12">
                    <p
                      className={`${font?.MNPG_HDX_01} md:w-80 md:pt-8 pt-10 pl-4 pb-4  grow`}
                    >
                      {mnpgHdx1?.map((line, i, mnpgHdx1) => {
                        return i + 1 == mnpgHdx1.length ? (
                          <span key={"MNPG_HDX_01" + i}>
                            {line}
                            <span
                              className={`${font?.MNPG_HDX_01_RM_TXT}  cursor-pointer`}
                              onClick={() => setReadMoreVisibility(true)}
                            >
                              {" "}
                              Read More
                            </span>
                          </span>
                        ) : (
                          <span key={"MNPG_HDX_01" + i}>
                            {line}
                            <br />
                          </span>
                        );
                      })}
                    </p>

                    <div className="md:w-[249px] w-48 h-auto md:pt-4 pt-12 md:pl-28  pb-4">
                      <img
                        className=" md:w-[120px] w-28 md:h-[127px] h-36  object-cover md:p-2 px-1 md:ml-0 ml-3"
                        alt="MNPG_HDXIMG_01"
                        src={
                          process.env.REACT_APP_STRAPI_API_URL +
                          coverdata.attributes?.MNPG_HDXIMG_01?.data?.attributes
                            ?.url
                        }
                      />
                      <p className=" relative self-stretch  text-right tracking-[0] leading-[normal] pt-2 w-32  md:w-72  h-10  md:right-44 right-2">
                        <span className={`${font?.MNPG_HDXIMGTX} `}>
                          {coverdata.attributes?.MNPG_HDXIMGTX_01}
                        </span>
                        <br />
                        <span className={`${font?.MNPG_HDXIMGTX} `}>
                          {coverdata.attributes?.MNPG_HDXIMGTX_02}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full justify-end lg:place-items-end">
                    {/* We are Montana.. */}
                    <div className="lg:static absolute bg-[#6B684D]  w-72 h-48 gap-0  flex lg:max-w-[546px] flex-col items-stretch lg:pl-5 lg:pr-6 lg:pt-4 pb-4 max-lg:px-5 lg:top-10  lg:right-[450px] lg:my-0 -my-14 lg:mx-0 mx-2 ">
                      <div
                        className={`${font?.HDX}  text-white text-right text-[14px]  mt-4 max-w-full`}
                      >
                        {coverdata.attributes?.MNPG_HDX_02?.split(/\n/).map(
                          (line) => (
                            <span key={line}>
                              {line}
                              <br />
                            </span>
                          )
                        )}
                      </div>
                      <img
                        alt="35years"
                        src="/img/35 years icon-01 1.png"
                        className="aspect-square object-contain object-center w-14 overflow-hidden max-w-full mt-1.5 self-end"
                      />
                    </div>
                  </div>
                </div>
                {isReadMoreVisible ? (
                  <>
                    <div className="justify-center lg:items-baseline flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                      <div className="relative w-auto my-6 mx-auto max-w-6xl bg-[#eaeaea] h-auto">
                        {/*content*/}
                        <div className="border-0  shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                          <div className="p-4">
                            <div className="flex h-auto p-4 ">
                              <div className="p-4 w-full"></div>
                              <img
                                className=" md:w-[120px] w-28 md:h-[127px] h-auto  object-cover "
                                alt="MNPG_HDXIMG_01"
                                src={
                                  process.env.REACT_APP_STRAPI_API_URL +
                                  coverdata.attributes?.MNPG_HDXIMG_01?.data
                                    ?.attributes?.url
                                }
                              />
                            </div>
                            <div className="flex items-center justify-end ">
                              <div className="w-full"></div>
                              <p className=" relative self-stretch  text-right tracking-[0] leading-[normal]   w-72 h-10 ">
                                <span
                                  className={`${font?.MNPG_HDX_01}  tracking-[0]`}
                                >
                                  {coverdata.attributes?.MNPG_HDXIMGTX_01}
                                </span>
                                <br />
                                <span
                                  className={`${font?.MNPG_HDX_01}  tracking-[0]`}
                                >
                                  {coverdata.attributes?.MNPG_HDXIMGTX_02}
                                </span>
                              </p>
                            </div>
                            <div className="flex  h-auto bg-[#eaeaea] pr-2 pt-2">
                              <p
                                className={`${font?.MNPG_HDX_01}  pt-2 pl-4 pb-2  grow`}
                              >
                                {coverdata.attributes?.MNPG_HDX_01_RM?.split(
                                  /\n/
                                ).map((line, i) => (
                                  <span key={line + "MNPG_HDX_01_RM" + i}>
                                    {line}
                                    <br />
                                  </span>
                                ))}
                              </p>
                            </div>
                            <div className="flex  h-auto bg-[#eaeaea] items-center justify-start pl-2">
                              <div
                                className={`${font?.MNPG_HDX_01} text-black p-2 leading-4`}
                              >
                                {" "}
                                <p className=" relative self-stretch  text-left tracking-[0] leading-[normal]   w-72 h-10 ">
                                  {coverdata.attributes?.MNPG_HDX_01_RM_SIGN?.split(
                                    /\n/
                                  ).map((line) => (
                                    <span key={line + "MNPG_HDX_01_RM_SIGN"}>
                                      {line}
                                      <br />
                                    </span>
                                  ))}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-center justify-end pt-6 bg-[#eaeaea]">
                              <div
                                onClick={() => setReadMoreVisibility(false)}
                                className="cursor-pointer justify-end"
                              >
                                <svg
                                  width="50"
                                  height="50"
                                  fill="Black"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z"></path>
                                </svg>
                              </div>
                            </div>
                            <div
                              className="flex items-center justify-end  bg-[#eaeaea] cursor-pointer"
                              onClick={() => setReadMoreVisibility(false)}
                            >
                              <span>Back To Home</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="h-full  bg-gray-300 lg:pt-28 pt-32">
            <div className="lg:max-w-[1100px] m-auto">
              <div className="text-center pt-6 lg:pt-14">
                {/* <span className={`${font?.SUHTX} px-4 text-black`}>
                  {coverdata.attributes?.MNPG_SUHTX_01}
                </span> */}
                <p
                  className={`${font?.SUHTX} text-black text-center px-4 pl-2 pr-2`}
                >
                  {coverdata.attributes?.MNPG_SUHTX_01?.split(/\n/).map(
                    (line, i) => (
                      <span key={`MNPG_SUHTX_01${i}`}>
                        {line} <br />
                      </span>
                    )
                  )}
                </p>
              </div>
              <div className="flex justify-center pt-4">
                <p
                  className={`${font?.SUHDX} text-black text-center pl-2 pr-2`}
                >
                  {coverdata.attributes?.MNPG_SUHDX_01?.split(/\n/).map(
                    (line, i) => (
                      <span key={`MNPG_SUHDX_01${i}`}>
                        {line} <br />
                      </span>
                    )
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default Main;
