import React from "react";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
//import { FontContext } from "../App";
import SolutionBy from "../Components/SolutionBy";
import { useFont } from "../contexts/FontContext";
import Page from "../Components/Page";

const SolutionByBM = () => {
  const [error, setError] = useState(null);
  const [bussinesdata, setbussinesdata] = useState([]);
  const fontValue = useFont();
  const font = fontValue?.font;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/bybm?populate=*")
      .then(({ data }) => setbussinesdata(data.data.attributes))
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <div key="solutionByBM">
        <SolutionBy
          font={font}
          solutionData={{
            SUHTX_01: bussinesdata.BYBM_SUHTX_01,
            CATHTX_1: bussinesdata.CATCHTX_2,
            BY1_HDTX_01: bussinesdata.BYBM1_HDTX_01,
            BY1_IMG_01A: bussinesdata.BYBM1_IMG_01A?.data?.attributes?.url,
            BY1_IMG_01B: bussinesdata.BYBM1_IMG_01B?.data?.attributes?.url,
            BY1_DTX_01A: bussinesdata.BYBM1_DTX_01A,
            BY2_HDTX_01: bussinesdata.BYBM2_HDTX_01,
            BY2_IMG_01A: bussinesdata.BYBM2_IMG_01A?.data?.attributes?.url,
            BY2_IMG_01B: bussinesdata.BYBM2_IMG_01B?.data?.attributes?.url,
            BY2_DTX_02A: bussinesdata.BYBM2_DTX_02A,
            BY3_HDTX_01: bussinesdata.BYBM3_HDTX_01,
            BY3_IMG_01A: bussinesdata.BYBM3_IMG_01A?.data?.attributes?.url,
            BY3_IMG_01B: bussinesdata.BYBM3_IMG_01B?.data?.attributes?.url,
            BY3_DTX_03A: bussinesdata.BYBM3_DTX_03A,
            BY4_HDTX_01: bussinesdata.BYBM4_HDTX_01,
            BY4_IMG_01A: bussinesdata.BYBM4_IMG_01A?.data?.attributes?.url,
            BY4_IMG_01B: bussinesdata.BYBM4_IMG_01B?.data?.attributes?.url,
            BY4_DTX_04A: bussinesdata.BYBM4_DTX_04A,
          }}
          type={"BM"}
        />
      </div>
    </Page>
  );
};

export default SolutionByBM;
