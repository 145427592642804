import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useFont } from "../../contexts/FontContext";
import Page from "../../Components/Page";

import HomeButton from "../../Components/HomeButton";

const NewbosProduct = () => {
  const fontValue = useFont();
  const font = fontValue?.font;

  const [error, setError] = useState(null);
  const [newBosData, setnewBosData] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_STRAPI_API_URL + "/api/bypg1?populate=*")
      .then(({ data }) => setnewBosData(data.data))
      .catch((error) => setError(error));
  }, []);

  return (
    <Page isLoading={fontValue?.loading}>
      <div className="lg:max-w-[1100px] m-auto ">
        <div className="flex justify-between items-center px-4 py-2 sticky">
          <div className="flex items-center  pt-2 pb-2 ">
            <img
              src={
                process.env.REACT_APP_STRAPI_API_URL +
                newBosData.attributes?.topLogo?.data?.attributes?.url
              }
              alt="mainlogo"
              className="w-40"
            ></img>
          </div>
        </div>
      </div>
      <div className="max-w[3840px] mx-auto relative">
        <div>
          <Carousel
            showStatus={false}
            autoPlay={true}
            showIndicators={false}
            showThumbs={false}
            infiniteLoop={true}
            showArrows={false}
            transitionTime={2000}
            centerMode={false}
            interval={10000}
            stopOnHover={false}
          >
            <img
              src={
                process.env.REACT_APP_STRAPI_API_URL +
                newBosData.attributes?.BYPG1_IMG_01A?.data?.attributes?.url
              }
              alt="/"
              className="w-full lg:max-h-[550px] max-h-[180px] object-cover"
            ></img>

            <img
              src={
                process.env.REACT_APP_STRAPI_API_URL +
                newBosData.attributes?.BYPG1_IMG_01B?.data?.attributes?.url
              }
              alt="/"
              className="w-full lg:max-h-[550px] max-h-[180px] object-cover"
            ></img>

            <img
              src={
                process.env.REACT_APP_STRAPI_API_URL +
                newBosData.attributes?.BYPG1_IMG_01C?.data?.attributes?.url
              }
              alt="/"
              className="w-full lg:max-h-[550px] max-h-[180px] object-cover"
            ></img>
          </Carousel>
        </div>
        <div className="lg:max-w-[1100px] m-auto  lg:py-6 py-2 p-2">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <video
              id="nbvideoPlay"
              className=" lg:col-span-2 w-full lg:w-[560px] h-full lg:h-[300px]"
              controls
              src={newBosData.attributes?.BYPG1_VID_01A}
            ></video>
            <div className="col-span-1 space-y-2 h-full">
              <p className={`${font?.DTX} pt-4`}>
                {newBosData.attributes?.BYPG1_DTX_01B}
              </p>
            </div>
            <div className="col-span-1 space-y-2 h-full">
              <p className={`${font?.DTX} pt-4`}>
                {newBosData.attributes?.BYPG1_DTX_02B}
              </p>
            </div>
            <div className="col-span-1 h-full">
              <p className={`${font?.DTX} pt-4`}>
                {newBosData.attributes?.BYPG1_DTX_03B}
              </p>
            </div>
            <div className="col-span-1 space-y-4 h-full">
              <div className="w-[350px] h-56 bg-[#404040] p-2">
                <p className="p-2 w-56">
                  {newBosData.attributes?.BYPG1_PDF_01a?.split(/\n/).map(
                    (line) => (
                      <span
                        key={`BYPG1_PDF_01a${line}`}
                        className={`${font?.HTX} text-white leading-3`}
                      >
                        {line}
                        <br />
                      </span>
                    )
                  )}
                </p>
                <div className="flex ">
                  <input type="email" className="h-7 w-56" />
                  <div className="">
                    <img
                      className="pl-11  h-16 "
                      alt="Pdf Icon"
                      src="/Pdf_Icon.png"
                    />
                  </div>
                </div>
              </div>
              <div className=" bg-[#161C2E] w-[350px] h-48 p-2">
                <p className={`${font?.HTX} text-white leading-4 p-2`}>
                  Would you like to have a further discussion with us :
                  <br /> <br />
                  Call Us : <br />
                  559-667-4150 <br />
                  <br />
                  Send us a email and we will get back to you as soon as
                  possible :
                </p>
                <input type="email" className=" w-full" />
              </div>
            </div>
          </div>
        </div>
        <HomeButton />
      </div>
    </Page>
  );
};

export default NewbosProduct;
