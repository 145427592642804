import React, { useState, useEffect } from "react";
import Main from "./Main";

import SolutionByIndustry from "./SolutionByIndustry";
import SolutionByBM from "./SolutionByBM";
import SolutionByRL from "./SolutionByRL";
import SolutionByGoal from "./SolutionByGoal";
import SolutionByProd from "./Product/SolutionByProd";
import ScheduleDemo from "./ScheduleDemo";
import { useNavigate, useParams } from "react-router-dom";
import Scroller from "./Scroller";
import { lazy } from "react";

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  // Save scroll position to localStorage when component unmounts
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Save scroll position to localStorage before page reloads
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("scrollPosition", scrollPosition);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [scrollPosition]);

  return (
    <div>
      <Main />
      <SolutionByIndustry />
      <SolutionByBM />
      <SolutionByRL />
      <SolutionByGoal />
      <SolutionByProd />
      <ScheduleDemo />
      <Scroller />
    </div>
  );
};

export default Home;
